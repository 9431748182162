@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.imagecard-wrapper {
  height: auto;
  display: flex;
  justify-content: center;

  .imagecard-header-wrapper {
    @include max-screen(small) {
      max-width: 100%;
    }
    @include min-screen(small) {
      max-width: 312px;
    }
  }

  .imagecard {
    max-width: fit-content;
    margin: 10px;

    .imagecard-image {
      max-width: 100%;
      max-height: 100%;
      aspect-ratio: 16/9;
    }
    .imagecard-button {
      color: white;
      background-color: $primary-green;
      padding: 12px 32px;
      font-size: 1.8rem;
      min-width: 64px;
      &:hover {
        background-color: $accent-green;
      }
    }
    .imagecard-heading {
      @include heading-font();
      text-decoration: none;
    }
    .imagecard-desc {
      @include body-font();
      text-decoration: none;
      margin-top: 10px;
    }

    .imagecard-link-container {
      padding: 8px;
    }

    .imagecard-aa {
      text-decoration: none;
    }
  }
}
